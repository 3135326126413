import React, { useEffect, useState, lazy, Suspense } from 'react';
import { setClipboardData } from '../../../../utils/common';
import { useLocation } from 'react-router-dom';
import './style.scss';

const TabBar = lazy(() => import('../tabBar/index.js'));


const OrderDetailPage = () => {
  const [order, setOrder] = useState(null);

  const location = useLocation();
  const { orderDetail } = location.state || {};

  const tabBarConfig = [
    {
      title: '首页',
      path: '/shanghaiCultureTourism',
      icon: 'https://staticfile.badazhou.com/20250314/0dbef58fc98af4a8661f97c5e7976cd0.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/c9487140908382a0c0c64e040a1c6d77.png',
      selected: false,
      replace: true,
    },
    {
      title: '我的订单',
      path: '/shanghaiCultureTourism/order',
      icon: 'https://staticfile.badazhou.com/20250314/073d08fa59df0e644ba3205bddfed8e6.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/32fedf07c7fec0f441d9f20321170727.png',
      selected: true,
      replace: true,
    }
  ];

  useEffect(() => {
    if (orderDetail) {
      setOrder(orderDetail);
    }
  }, [orderDetail]);


  if (!order) return <div>加载中...</div>;

  return (
    <div className="jsbc-order-detail-page">

      <div className={`header status${[order.status]}`}>
        <div className="title">{order.status_label}</div>
        {order.status !== 3 && <div className='fs-12'>门票订单需二次确认，下单后2个工作日内预订成功，出行人手机号收到预订成功短信，凭[身份证]直接入园。</div>}
      </div>

      <div className='module special'>

        <div className="fs-16 bold mb-20">{order?.ext?.productName}</div>
        <div className="view flex-row center">
          <div className="label">预订人</div>
          <div className="value">{order?.jiangsu_bank_vip_info?.name}</div>
        </div>
        <div className="view flex-row center">
          <div className="label">入园日期</div>
          <div className="value">{order?.id_card_date_list[0]?.date}</div>
        </div>
        <div className="view flex-row center">
          <div className="label">购买数量</div>
          <div className="value bold">{order?.ext?.persons.length}</div>
        </div>
        <div className="view flex-row center">
          <div className="label">支付金额</div>
          <div className="value bold c-orange fs-18">¥{order.amount}</div>
        </div>
        <div className="view flex-row center">
          <div className="label">订单编号</div>
          <div className="value flex-row h-between">
            <div>{order.order_no}</div>
            <div className='c-link' onClick={() => {
              setClipboardData(`订单编号：${order.order_no}`)
            }}>复制</div>
          </div>
        </div>
        <div className="view flex-row center">
          <div className="label">预订时间</div>
          <div className="value">{order.create_time}</div>
        </div>
      </div>

      <div className='module'>
        <div className="fs-16 bold mb-10">出行信息</div>
        {order.ext?.persons?.map((item, index) => (
          <div key={index}>
            {order.ext?.persons?.length > 1 && <div className="bold mb-10 mt-20">游客{index + 1}</div>}
            <div className="view flex-row center">
              <div className="label">姓名</div>
              <div className="value">{item.name}</div>
            </div>
            <div className="view flex-row center count-view">
              <div className="label">手机号</div>
              <div className="value">{item.phone}</div>
            </div>
            <div className="view flex-row center count-view">
              <div className="label">身份证</div>
              <div className="value">{item.idCard}</div>
            </div>
          </div>
        ))}

      </div>

      <div className='contact-box flex-row h-between v-center'>
        <div className="c-66">
          <div>如果您对门票订单有任何问题</div>
          <div>可以添加我们的企业微信客服</div>
          <div>客服：Nancy</div>
        </div>
        <img className='code' src="https://staticfile.badazhou.com/20250314/ed7372e65e5ed8c57bc91d56ff1ca593.jpeg" alt='客服' />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <TabBar data={tabBarConfig} />
      </Suspense>


    </div>
  );
};

export default OrderDetailPage;
