import React, { useState, useEffect, lazy, Suspense } from "react";
import { orderList } from "../../../../api/subject.js";
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd';
import "./style.scss";

const TabBar = lazy(() => import('../tabBar/index.js'));
const statusDit = {
  1: 'pending',
  2: 'success',
  3: 'failed'
}

const OrderPage = () => {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [phone, setPhone] = useState('--');
  const [orderStats, setOrderStats] = useState({
    total: 0,
    completed: 0,
    pending: 0,
  });

  const [loading, setLoading] = useState(false);

  const tabBarConfig = [
    {
      title: '首页',
      path: '/shanghaiCultureTourism',
      icon: 'https://staticfile.badazhou.com/20250314/0dbef58fc98af4a8661f97c5e7976cd0.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/c9487140908382a0c0c64e040a1c6d77.png',
      selected: false,
      replace: true,
    },
    {
      title: '我的订单',
      path: '/shanghaiCultureTourism/order',
      icon: 'https://staticfile.badazhou.com/20250314/073d08fa59df0e644ba3205bddfed8e6.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/32fedf07c7fec0f441d9f20321170727.png',
      selected: true,
      replace: true,
    }
  ];

  useEffect(() => {
    const phone = JSON.parse(localStorage.getItem('jsbc_user'))?.phone;
    if (phone) setPhone(phone);

    orderList()
      .then((res) => {
        const list = res.data.list || [];

        // 计算订单统计
        const total = list.length;
        const completed = list.filter((o) => o.status === 2).length;
        const pending = list.filter((o) => o.status === 1).length;

        setOrders(list);
        setOrderStats({ total, completed, pending });
      })
      .catch((err) => {
        console.error("获取订单数据失败：", err);
      });
  }, []);

  function goToOrderDetail(order) {
    history.push({
      pathname: `/shanghaiCultureTourism/orderDetail`,
      state: { orderDetail: order }
    });
  }

  async function goToOrderPay(order) {
    // console.log('order: ', order);
    setLoading(true);
    try {
      const { form_action, form_data } = order?.pay_order_info;
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = form_action;

      for (const key in form_data) {
        if (form_data.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = form_data[key];
          form.appendChild(input);
        }
      }
      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      message.error(err?.response?.data?.msg);
      setLoading(false);
    }
  }

  return (
    <div className="jsbc-order-page">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <Spin size="middle" />
            <div className="loading-text">订单提交中...</div>
          </div>
        </div>
      )}

      <div className="user-info">
        <img className="avatar" src='https://staticfile.badazhou.com/20250313/2509ff0cb3d68d34733b39dea0e34426.png' alt="头像" />
        <div className="phone-number">{phone ? phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : ''}</div>
      </div>
      <div className="order-summary">
        <div className="summary-item">
          <div className="summary-number">{orderStats.total}</div>
          <div className="summary-label">全部订单</div>
        </div>
        <div className="summary-item">
          <div className="summary-number">{orderStats.completed}</div>
          <div className="summary-label">完成订单</div>
        </div>
        <div className="summary-item">
          <div className="summary-number">{orderStats.pending}</div>
          <div className="summary-label">待支付订单</div>
        </div>
      </div>


      {orders.length > 0 ? (
        orders.map((order) => (
          <div className="order-card" key={order.order_no}>
            <div className="order-header">
              <span className="bold fs-16">{order?.ext?.productName}</span>
              <span className={`order-status ${statusDit[order.status]}`}>
                {order.status_label}
              </span>
            </div>
            <div className="order-content" onClick={() => goToOrderDetail(order)}>
              <img className="order-img" src={order?.ext?.image} alt="订单图片" />
              <div className="order-info">
                <div className="fs-12 c-66 mb-10">订单编号：{order.order_no}</div>
                <div className="fs-12 c-66">预订时间：{order.create_time}</div>
              </div>
            </div>
            <div className="flex-row v-center h-between">
              <div>
                <span className="c-33 fs-14">支付金额</span> <span className="c-orange bold">¥{order.amount}</span>
              </div>
              <div>
                {
                  order.status_label === '待支付' &&
                  <button className="order-detail-btn-pay mr-10" onClick={() => goToOrderPay(order)} disabled={loading}>去支付</button>
                }
                <button className="order-detail-btn" onClick={() => goToOrderDetail(order)}>查看详情</button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-orders">暂无订单</div>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        <TabBar data={tabBarConfig} />
      </Suspense>
    </div>
  );
};

export default OrderPage;
