import { message } from 'antd';
import dayjs from 'dayjs';

import { FESTIVAL_DATE } from './constants';

// 获取本地存储
export const getStorage = (name) => {
  return localStorage.getItem(name);
}
// 设置本地存储
export const setStorage = (name, val) => {
  localStorage.setItem(name, val);
}
// 移除本地缓存
export const removeStorage = (name) => {
  localStorage.removeItem(name);
}

// 获取设备的宽高
export const systemInfo = () => {
  const width = document.documentElement.clientWidth;
  const height = document.documentElement.clientHeight;
  return { width, height }
}

// 防抖函数
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
      clearTimeout(timer);
    }, delay);
  }
}

// 复制到粘贴板功能
export const setClipboardData = (str) => {
  // 创建input标签存放需要复制的文字
  const oInput = document.createElement('input');
  // 把文字放进input中，供复制
  oInput.value = str;
  document.body.appendChild(oInput);
  // 选中创建的input
  oInput.select();
  // 执行复制方法， 该方法返回bool类型的结果，告诉我们是否复制成功
  const copyResult = document.execCommand('copy');
  // 操作中完成后 从Dom中删除创建的input
  document.body.removeChild(oInput);
  // 根据返回的复制结果 给用户不同的提示
  if (copyResult) {
    message.success('复制成功');
  } else {
    message.error('复制失败');
  }
}

// 解析地理位置
export function parsePath(hotelPath) {
  const pathArr = hotelPath.split(',');
  const path = `${pathArr[0]},${pathArr[1]}`;
  return path;
}

// 判断目的地是否是国内
export function destIsChina(hotelPath) {
  const path = parsePath(hotelPath);
  const isChina = path === '1,7';
  return isChina;
}

// 将请求的数据 有的字段需要补全数据
export function objValue(name, value) {
  let allEnumList = getStorage('allEnumValues');
  if (allEnumList) {
    allEnumList = JSON.parse(allEnumList);
    return allEnumList[name].find(v => v.value === value);
  }
}
// 获取随机数
export function getRandomNumber(a, b) {
  return parseInt((a + Math.random() * (b - a + 1)));
}

// 获取日期是周几
const weekList = [
  { name: '星期天', shortName: '周日' },
  { name: '星期一', shortName: '周一' },
  { name: '星期二', shortName: '周二' },
  { name: '星期三', shortName: '周三' },
  { name: '星期四', shortName: '周四' },
  { name: '星期五', shortName: '周五' },
  { name: '星期六', shortName: '周六' },
];

// 获取简短日期星期
export function getShortWeek(date, nowDate) {
  const week = dayjs(date).get('day');
  let weekName = weekList[week].shortName;
  // 如果传入当天时间
  if (nowDate) {
    const diffDayList = ['今天', '明天', '后天'];
    const diffDay = dayjs(date).diff(nowDate, 'day');
    if (diffDay >= 0 && diffDay < 3) {
      weekName = diffDayList[diffDay]
    }
  }
  return weekName;
}

// 获取简短日期
export function getShortDate(date) {
  if (dayjs().year() === dayjs(date).year()) {
    return dayjs(date).format('MM-DD');
  } else {
    return date;
  }
}

// 引入微信 JS-SDK
export const loadWeChatSDK = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    script.onload = () => {
      resolve(window.wx);
    };
    script.onerror = () => {
      reject(new Error('WeChat SDK load error'));
    };
    document.head.appendChild(script);
  });
};

export function calculateTargetDateWithHolidays(startDate) {
  const holidayData = {...FESTIVAL_DATE};
  // 1. 将节假日数据转换为 Map 对象（键格式：YYYY/M/D，值：isWorkingDay）
  const holidayMap = new Map();
  Object.entries(holidayData).forEach(([dateStr,  data]) => {
      holidayMap.set(dateStr,  data.isWorkingDay); 
  });

  // 2. 初始化日期副本并移动到下一个自然日 
  const currentDate = new Date(startDate);
  currentDate.setDate(currentDate.getDate()  + 1);
  let count = 0;

  // 3. 遍历日期直到累计2个有效工作日 
  while (count < 2) {
    // 3.1 生成当前日期的字符串键（YYYY/M/D）
    const dateKey = `${currentDate.getFullYear()}/${currentDate.getMonth()  + 1}/${currentDate.getDate()}`; 
    
    // 3.2 判断是否为工作日（优先取节假日数据，否则判断是否为周一到周五）
    let isWorkingDay;
    if (holidayMap.has(dateKey))  {
        isWorkingDay = holidayMap.get(dateKey);  // 法定节假日或调休数据 
    } else {
        const day = currentDate.getDay();  // 0=周日, 1=周一...6=周六 
        isWorkingDay = (day >= 1 && day <= 5); // 默认周一到周五为工作日 
    }

    // 3.3 累计有效工作日 
    if (isWorkingDay) {
        count++;
    }

    // 3.4 若尚未满足条件，继续递增日期 
    if (count < 2) {
        currentDate.setDate(currentDate.getDate()  + 1);
    }
  }

  // 4. 返回第2个工作日的次日 
  currentDate.setDate(currentDate.getDate()  + 1);
  return dayjs(currentDate).format('YYYY-MM-DD');
}
 
