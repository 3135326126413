import React, { useState, useEffect, Suspense } from 'react';
import { useParams, Link } from "react-router-dom";
import lodash from 'lodash';
import { message } from 'antd';
import dayjs from 'dayjs';
import { PhotoSwiper } from '../../../../../components';
import { productInfo, disneyPriceList, systemTime } from '../../../../../api';
import { calculateTargetDateWithHolidays } from '../../../../../utils/common';
import './style.scss';



const ProductDetail = () => {
  const no = useParams().no;
  const [baseInfo, setBaseInfo] = useState(null);
  const [minPrices, setMinPrices] = useState(null);

  useEffect(() => {
    fetchPriceList();
    // const min_day_ticket = {displayPrice: '475.00'};
    // const min_early_bird_ticket = {displayPrice: '425.00'}
    // setMinPrices({
    //   min_day_ticket: Number(min_day_ticket.displayPrice),
    //   min_early_bird_ticket:  Number(min_early_bird_ticket.displayPrice)
    // })
    // 获取产品详情
    productInfo(no).then((res) => {
      setBaseInfo(res.data);
    }).catch((err) => { });
  }, [no]);

  async function fetchPriceList() {
    try {
      const current =  await systemTime();
      const currentDate = current.data.date;
      
      const res = await disneyPriceList();

      //当前日期 和 预订日期 之间必须包含 2个工作日，计算最早可预订时间
      const startDate = calculateTargetDateWithHolidays(currentDate);
      let day_ticket = Object.entries(res.data.day_ticket.date_data).map(([date, value]) => ({ ...value, date }));
      let index1 = day_ticket.findIndex(v => v.date === startDate);
      day_ticket = day_ticket.slice(index1);

      // 早鸟根据当前日期 在往后推10天
      const birdCurrentDate = dayjs(currentDate).add(10,'day').format('YYYY-MM-DD');
      const birdStartDate = calculateTargetDateWithHolidays(birdCurrentDate);
      let early_bird_ticket = Object.entries(res.data.early_bird_ticket.date_data).map(([date, value]) => ({ ...value, date }));
      let index2 = early_bird_ticket.findIndex(v => v.date === birdStartDate);
      early_bird_ticket = early_bird_ticket.slice(index2);
      // 使用 lodash 获取最低价格的对象
      const min_day_ticket = lodash.minBy(day_ticket, (item) => parseFloat(item.displayPrice));
      const min_early_bird_ticket = lodash.minBy(early_bird_ticket, (item) => parseFloat(item.displayPrice));


      setMinPrices({
        min_day_ticket: Number(min_day_ticket.displayPrice),
        min_early_bird_ticket: Number(min_early_bird_ticket.displayPrice)
      });


    } catch (err) {
      message.error('门票价格获取失败');
    }
  }


  return (
    <div className="product-detail-wrapper">
      {baseInfo && (
        <>
          <div className="photos-wrapper">
            <Suspense fallback={<div>Loading...</div>}>
              <PhotoSwiper images={baseInfo.images} imgDimension="750x450" swiperHeight="4.5rem" />
            </Suspense>
          </div>

          <div className="module base-info">
            <div className="title">上海迪士尼乐园门票</div>
            <div className="c-warn fs-13 mb-10">仅限成人票，非儿童票/老人票，门票价格以入园日期为准</div>

            <div className="ticket-list">
              <Link to={{ pathname: '/shanghaiCultureTourism/ticket/disney_booking/1' }} className="flex-row h-between">
                <div className="ticket flex-row v-center h-between">
                  <img
                    src="https://staticfile.badazhou.com/20241023/b488ff7d1430e0dcd08211edd17ba8ca.jpeg-500x300"
                    alt="普通票"
                    className="ticket-image"
                  />
                  <div className='info'>
                    <div className='flex-row price mb-10'>
                      <div className='bold mr-10 fs-16 c-33'>普通票</div>
                      {minPrices && (
                        <div>
                          <span className='origin-price'>¥{minPrices?.min_day_ticket}</span>
                          <span className='fs-14 c-orange'>¥</span>
                          <span className='fs-21 c-orange'>{minPrices?.min_day_ticket - 28}</span>
                          <span className='fs-10 c-orange'> 起</span>
                        </div>
                      )}
                    </div>
                    <div className="booking-btn">立即预订</div>
                  </div>
                </div>
              </Link>

              <Link to={{ pathname: '/shanghaiCultureTourism/ticket/disney_booking/2' }} className="flex-row h-between">
                <div className="ticket flex-row v-center h-between">
                  <img
                    src="https://staticfile.badazhou.com/20241023/f5928a048b1cb6d69a30a7f4d725e59a.jpeg-500x300"
                    alt="早鸟票"
                    className="ticket-image"
                  />
                  <div className='info'>
                    <div className='flex-row price mb-10'>
                      <div className='bold mr-10 fs-16 c-33'>早鸟票</div>
                      {minPrices && (
                        <div>
                          <span className='origin-price'>¥{minPrices?.min_early_bird_ticket}</span>
                          <span className='fs-14 c-orange'>¥</span>
                          <span className='fs-21 c-orange'>{minPrices?.min_early_bird_ticket - 23}</span>
                          <span className='fs-10 c-orange'> 起</span>
                        </div>
                      )}
                    </div>
                    <div className="booking-btn">立即预订</div>
                  </div>
                </div>
              </Link>
            </div>

          </div>


          {/* <div
            className="module description"
            dangerouslySetInnerHTML={{ __html: baseInfo.description }}
          /> */}
          <div className="module description fs-14">
            <p className='mb-10 desc'>欢迎来到一个前所未见的神奇世界，在此点亮您的心中奇梦。这就是上海迪士尼乐园，无论老幼，都可以在此感受充满创造力、冒险和刺激的乐趣！</p>
            <p className='mb-10 desc'>把目光投向奇幻童话城堡，世界上最大的迪士尼城堡，准备好开始探索这八大各具魅力而令人难忘的神奇园区：米奇大街、奇想花园、梦幻世界、探险岛、宝藏湾、明日世界、迪士尼·皮克斯玩具总动员和疯狂动物城主题园区。</p>
         
            <p style={{height: '30px'}}></p>
            <p className='mb-10 bold'>预订及兑换方式：</p>
            <p className='mb-10'>1. 普通票至少提前3个工作日，早鸟票至少提前13天预订；</p>
            <p className='mb-10'>2. 预订时需提供入园日期、门票数量、入园人姓名、身份证号、手机号；</p>
            <p className='mb-10'>3. 预订确认后将以短信形式发放门票信息；</p>
            <p className='mb-10'>4. 门票仅限本人使用，不可全部或部分转让、取消或退改，</p>
            <p>相关规则请参考上海迪士尼度假区信息及通知。</p>

            <p className='mt-20 c-warn'>开放时间：<span>以上海迪士尼园区公示时间为准</span></p>
            <p className='mt-10 c-warn'>乐园地址：<span>上海市浦东新区黄赵路310号</span></p>
            
          
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDetail;
