import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, message, Spin } from 'antd';
import dayjs from 'dayjs';
import { PriceCalendar } from "../../../../../components";
import { jsbcCreateOrder, disneyPriceList, systemTime } from '../../../../../api';
import { calculateTargetDateWithHolidays } from '../../../../../utils/common';

import './style.scss';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.104:3001' : 'https://m.badazhou.com';

const PurchasePage = () => {
  const type = useParams().type;
  let productName = type === '1' ? '上海迪士尼乐园1日成人票' : '上海迪士尼乐园早鸟1日成人票';
  // 早鸟票减23元一张，普通票减28元一张
  let discount_amount = type === '1' ? 28 : 23;

  const [prices, setPrices] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [persons, setPersons] = useState([{ name: '', phone: '', idCard: '' }]);
  const [calendarState, setCalendarState] = useState(false);
  const [data, setData] = useState({ totalPrice: '--', count: 1 });
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(-1);
  const [disabled, setDisabled] = useState();
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef();


  useEffect(() => {
    fetchPriceList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

   async function fetchPriceList() {
      try {
        const current =  await systemTime();
        const currentDate = current.data.date;
        const res = await disneyPriceList();

        
       
        let prices;
        if (type === '1') {
          //当前日期 和 预订日期 之间必须包含 2个工作日，计算最早可预订时间
          const startDate = calculateTargetDateWithHolidays(currentDate);
          prices = Object.entries(res.data.day_ticket.date_data).map(([date, value]) => ({ ...value, price: value.displayPrice ? Number(value.displayPrice) - discount_amount : 0, date }));
          let index1 = prices.findIndex(v => v.date === startDate);
          prices = prices.slice(index1);
        }
        if (type === '2') {
          const birdCurrentDate = dayjs(currentDate).add(10,'day').format('YYYY-MM-DD');
          const birdStartDate = calculateTargetDateWithHolidays(birdCurrentDate);
          prices = Object.entries(res.data.early_bird_ticket.date_data).map(([date, value]) => ({ ...value, price: value.displayPrice ? Number(value.displayPrice) - discount_amount : 0, date }));
          let index2 = prices.findIndex(v => v.date === birdStartDate);
          prices = prices.slice(index2);
        }
        
        // 如果数组最前面没价格日期就踢掉
        if (!prices[0].displayPrice) {
          const firstPriceIndex1 = prices.findIndex(item => item.displayPrice);
          prices = firstPriceIndex1 !== -1 ? prices.slice(firstPriceIndex1) : [];
        }

        setPrices([...prices]);

        const calendarData = { minDate: prices[0].date, maxDate: prices[prices.length - 1].date }
        setCalendarData({ ...calendarData });

      } catch (err) {
        message.error('门票价格获取失败');
      }
    }


  // 获取选择的日期
  function getChangeDate(day) {
    calendarRef.current.hide();
    const selectedPriceIndex = prices.findIndex(v => v.date === day.date);
    setSelectedPriceIndex(selectedPriceIndex)
    data.totalPrice = day.price * data.count;
    setData({ ...data })
  }


  const handleIncrement = () => {
    data.count = data.count + 1;
    data.totalPrice = prices[selectedPriceIndex].price * data.count;
    setData({ ...data });
    setPersons([...persons, { name: '', phone: '', idCard: '' }]);
  };

  const handleDecrement = () => {
    if (data.count >= 2) {
      data.count = data.count - 1;
      data.totalPrice = prices[selectedPriceIndex].price * data.count;
      setPersons(persons.slice(0, persons.length - 1));
    } else {
      message.error('预订数量最低为1')
    }
  };


  const handlePersonChange = (index, field, value) => {
    const newPersons = persons.map((person, i) =>
      i === index ? { ...person, [field]: value } : person
    );
    setPersons(newPersons);
  };

  // 手机号校验（例如针对中国手机号）
  const validatePhone = (phone) => {
    const regex = /^1[3-9]\d{9}$/;
    return regex.test(phone);
  };

  // 身份证校验（15位或18位，18位可带X或x）
  const validateIdCard = (idCard) => {
    const regex = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;
    return regex.test(idCard);
  };

  const handleSubmit = async (e) => {
    if (disabled || loading) {
      return;
    }

    e.preventDefault();

    if(selectedPriceIndex === -1) {
      message.error('请选择入园日期');
      return;
    }

    const idCardSet = new Set();

    for (let i = 0; i < persons.length; i++) {
      const person = persons[i];

      if (!person.name) {
        message.error(`游客${i + 1}：姓名不能为空`);
        return;
      }

      if (!validatePhone(person.phone)) {
        message.error(`游客${i + 1}：请输入正确的手机号`);
        return;
      }

      if (!validateIdCard(person.idCard)) {
        message.error(`游客${i + 1}：请输入正确的身份证号码`);
        return;
      }

      if (idCardSet.has(person.idCard)) {
        message.error('一个身份证同一日期限购1份');
        return;
      }

      idCardSet.add(person.idCard);
    }

    setDisabled(true);
    setLoading(true);

    try {
      // 总金额一定大于0
      if(data.totalPrice && data.totalPrice > 0) {
        const res = await jsbcCreateOrder({
          num: persons.length,
          product_no: "hizea5kuroon",
          total_amount: data.totalPrice + "",
          success_page_url: `${baseUrl}/shanghaiCultureTourism/order`,
          wait_page_url: `${baseUrl}/shanghaiCultureTourism/order`,
          id_card_date_list: persons.map(p => ({ date: prices[selectedPriceIndex]?.date, id_card: p.idCard })),
          ext: {
            persons,
            productName,
            image: type === '1' ? 'https://staticfile.badazhou.com/20241023/b488ff7d1430e0dcd08211edd17ba8ca.jpeg-500x300' : 'https://staticfile.badazhou.com/20241023/f5928a048b1cb6d69a30a7f4d725e59a.jpeg-500x300',
          }
        });
  
        const { form_action, form_data } = res.data;
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = form_action;
  
        for (const key in form_data) {
          if (form_data.hasOwnProperty(key)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = form_data[key];
            form.appendChild(input);
          }
        }
        document.body.appendChild(form);
        form.submit();
      }
    } catch (err) {
      message.error(err?.response?.data?.msg);
      setLoading(false);
      setDisabled(false);
    }
  };


  return (
    <div className="jsbc-purchase-page">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <Spin size="middle" />
            <div className="loading-text">订单提交中...</div>
          </div>
        </div>
      )}


      <div className="module">
        <div className="title">{productName}</div>
        {/* <div className="c-99 fs-12">*同一身份证<span className='c-orange'>同一使用日期限购1份</span>，<span className='c-orange'>凭[身份证]</span>直接入园，请准确填写游客信息。</div> */}
        <div className="view flex-row center count-view">
          <div className="label">入园日期</div>
          <div className="date flex1 flex-row center h-between" onClick={() => {
              setCalendarState(true);
              calendarRef.current.show();
            }}>
              {(prices && selectedPriceIndex >= 0) ?  (
                <div className='flex1'>{prices[selectedPriceIndex]?.date}</div>
              ) : (
                <div className='flex1 c-link'>请选择入园日期</div>
              )} 
            
            
            <div className="c-link fs-14" >
              {selectedPriceIndex >= 0 &&  <span>更换日期</span>}
             <span className="arrow iconfont icon-xiangyoujiantou"></span>
            </div>
          </div>
        </div>


        <div className="view flex-row center count-view">
          <div className="label">门票数量</div>
          <div className="count-box">
            <span className="change-btn subtract" onClick={handleDecrement}>-</span>
            <span className="count-num">{persons.length}</span>
            <span className="change-btn add" onClick={handleIncrement}>+</span>
          </div>
        </div>

      </div>

      <form className='module' onSubmit={handleSubmit}>

        <div className="persons-container">
          {persons.map((person, index) => (
            <div
              key={index}
              className="person-form"
            >
              {persons.length === 1 ? (
                <div className='sec-title'>游客信息</div>
              ) : (
                <div className='sec-title'>
                  <span>游客{index + 1}信息</span>
                </div>
              )}

              <div className="view flex-row center">
                <div className="label">姓名</div>
                <Input
                  className="input"
                  value={person.name}
                  onChange={(e) => handlePersonChange(index, 'name', e.target.value)}
                  placeholder="输入姓名"
                />
              </div>
              <div className="view flex-row center">
                <div className="label">手机号</div>
                <Input
                  className="input"
                  value={person.phone}
                  onChange={(e) => handlePersonChange(index, 'phone', e.target.value)}
                  placeholder="输入手机号"
                />
              </div>
              <div className="view flex-row center">
                <div className="label">身份证号</div>
                <Input
                  className="input"
                  value={person.idCard}
                  onChange={(e) => handlePersonChange(index, 'idCard', e.target.value)}
                  placeholder="输入身份证号"
                />
              </div>


            </div>
          ))}
        </div>


        <div className="bottom-box">
          <div className="flex-row v-center h-between">
            <div className="total-price">
              <span className='fs-14'>￥</span><span className='fs-24'>{data?.totalPrice ? data.totalPrice : '--'}</span>
            </div>
            <Button
              htmlType="submit"
              loading={loading}
              disabled={disabled || loading}
              style={{ color: disabled ? '#ffffff' : '#835825' }}
              className={disabled ? 'booking-btn-disabled' : 'booking-btn'}
            >
              提交订单
            </Button>
          </div>
        </div>
      </form>

      <div className="module">
        <div className="sec-title mb-20">预订须知</div>

        <div>1. 同一身份证<span className='c-orange'>同一使用日期限购1份</span>，1个身份证号对应1张门票，<span className='c-orange'>凭[身份证]</span>直接入园，请正确填写游客信息。</div>

        <div className='mt-10'>
          2. 下单后<span className="c-orange">2个工作日内</span>预订成功。具体以收到预订成功短信通知为准。
        </div>

        <div className='mt-10'>
          3. 本产品<span className="c-orange">付款成功后不可退票</span>。本产品不可转让，不可退款，不可取消，购买本产品时所使用的身份证件信息不可更改，除非法律另有规定。
        </div>

        <div className='mt-10'>如有疑问请咨询八大州旅游官方客服400 088 2020。</div>

      </div>


      <Suspense fallback={<div>Loading...</div>}>
        {calendarData && (
          <PriceCalendar
            minDate={calendarData.minDate}
            maxDate={calendarData.maxDate}
            calendarState={calendarState}
            currentDate={selectedPriceIndex >= 0 ? prices[selectedPriceIndex].date : ''}
            prices={prices}
            ref={calendarRef}
            onChange={getChangeDate}
          />
        )}


      </Suspense>
    </div>
  );
};


export default PurchasePage;
