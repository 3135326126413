import axios from 'axios';
import { message } from 'antd';
import { getStorage } from '../utils/common';

const CancelToken = axios.CancelToken;
let cancel;

const apiURL = 'https://api.badazhou.com';
// const apiURL2 = 'http://192.168.1.14:8282';
const apiURL2 = 'http://alpha.gapi.badazhou.com';

function request(url, data = {}, method = 'get', header = {}) {
  // 处理 url
  const newUrl = processUrl(url);
  // 处理 header
  // 总是把accessToken作为header头传给服务器
  const defaultHeader = {
    'Accept': 'application/json'
  };
  const token = getStorage('token') || '';
  if (token) {
    defaultHeader.Authorization = 'Bearer ' + token;
  }
  const headers = { ...defaultHeader, ...header };

  return new Promise(((resolve, reject) => {
    axios({
      method,
      url: newUrl,
      data,
      headers,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }).then((res) => {
      if (typeof res.data === 'object' || res.data === '') {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          // 请求数据成功 返回数据
          resolve(res.data);
        } else {
          message.error('请求异常');
        }
      } else {
        message.error('请求异常');
      }
    }).catch((error) => {
      if (error.response?.status === 401) {
        // 需要登录后才可以操作
        window.location.href = '/login';
      } else {
        // 统一提示错误信息
        // if(error?.response?.data?.message) {
        //   message.error(error?.response?.data?.message);
        // }
      }
      reject(error);
    });
  }));
}

// 处理Url
function processUrl(url) {
  // 处理 url
  let paramUrl = url;
  let newUrl = '';
  if (paramUrl.substr(0, 5) === 'https') {
    newUrl = paramUrl;
  } else {
    if (paramUrl.substr(0, 1) === '/') {
      paramUrl = paramUrl.substr(1);
    }
    // console.log(url);
    let urlStatus = 1
    if (url.indexOf('gapi') > -1) {
      urlStatus = 0
    }
    newUrl = `${urlStatus ? apiURL : apiURL2}/${paramUrl}`;
  }
  return newUrl;
}


function get(url, data = {}, headers) {
  return request(url, data, 'get', headers);
}

function del(url, data = {}, headers) {
  return request(url, data, 'delete', headers);
}

function put(url, data = {}, headers) {
  return request(url, data, 'put', headers);
}

function post(url, data = {}, headers) {
  return request(url, data, 'post', headers);
}

export function cancelRequest() {
  cancel();
}


export {
  post,
  get,
  del,
  put
};
