import queryString from 'query-string';
import { get, post } from './request';

// 酒店搜索
export function gdsHotelSearch(query) {
  let url = '/www/hotel/search';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// 酒店品牌
export function gdsHotelBrands(areaId) {
  return get(`www/gdsHotel/area/${areaId}/brands`);
}

// 酒店会员
export function gdsHotelMembers(areaId) {
  return get(`www/gdsHotel/area/${areaId}/members`);
}

// gds酒店列表
export function hotels(query) {
  let url = '/www/gdsHotel/search';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// 根据酒店编号获取列表价格
export function gdsListPrice(query) {
  let url = '/www/hotels/gdsListPrice';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// gds酒店房型列表
export function hotelRoom(hotelNo) {
  return get(`/www/hotel/${hotelNo}/rooms`);
}

// gds酒店基本详情
export function hotelInfo(hotelNo) {
  return get(`/www/hotel/${hotelNo}`);
}

// 获取酒店方案
export function ratePlan(hotelNo, query) {
  let url = `/www/hotels/${hotelNo}/gdsRates`;
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// 获取方案详情
export function gdsHotelRateDetail(no, rateNo) {
  return get(`www/hotels/${no}/gdsRates/${rateNo}`);
}

// 提交预订
export function gdsHotelBooking(no, rateNo, data) {
  return post(`/www/hotels/${no}/gdsRates/${rateNo}/booking`, data);
}

// 获取后台产品详情
export function productInfo(no) {
  return get(`/www/product/${no}`);
}
