import iconIndex from '../assets/images/icon-index.png';
import iconIndexActive from '../assets/images/icon-index-active.png';
import iconUser from '../assets/images/icon-user.png';
import iconUserActive from '../assets/images/icon-user-active.png';
import iconCustomer from '../assets/images/icon-customer.png';
import iconCustomerActive from '../assets/images/icon-customer-active.png';

// app tabBar配置
export const homeTabBar = [
  {
    title: '首页',
    path: '/',
    icon: iconIndex,
    selectedIcon: iconIndexActive,
    selected: true
  },
  {
    title: '我的',
    path: '/myCenter',
    icon: iconUser,
    selectedIcon: iconUserActive,
    selected: false
  }
];

export const hotelDetailTabBar = [
  {
    title: '首页',
    path: '/',
    icon: iconIndex,
    selectedIcon: iconIndexActive,
    selected: true,
    replace: true,
  },
  {
    title: '客服',
    path: '/customerService',
    icon: iconCustomer,
    selectedIcon: iconCustomerActive,
    selected: false,
    replace: false,
  }
];

export const tabBarMyCenter = [
  {
    title: '首页',
    path: '/',
    icon: iconIndex,
    selectedIcon: iconIndexActive,
    selected: false,
    replace : true,
  },
  {
    title: '我的',
    path: '/myCenter',
    icon: iconUser,
    selectedIcon: iconUserActive,
    selected: true,
    replace : true,
  }
];
// link url 
export const URL = {
  hotelList: '/hotel/list',
  hotelDetail: '/hotel/detail',
  hotelAreaList: '/hotel/area',
  myCenter: '/mycenter'
};

// 首页搜索默认城市
export const DEFAULT_CITY = {name: 'shanghai', nameCn: '上海', id: 255};
// 企业电话
export const enterprisePhone = '4000882020';
// 咨询客服链接
export const customerServiceLink = 'https://www.v5kf.com/public/chat/chat?sid=149754&entry=5&ref=link&accountid=248fa0301a7de';
// 日历组件常量
export const FESTIVAL_TAGS = {
  '01-01': '元旦',
  '02-14': '情人节',
  '05-01': '劳动节',
  '06-01': '儿童节',
  '10-01': '国庆',
  '12-24': '平安夜',
  '12-25': '圣诞节',
};

export const FESTIVAL_DATE ={
	"2024/12/25": {
		"name": "圣诞节",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/1/1": {
		"name": "元旦",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/1/26": {
		"name": "班",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/1/28": {
		"name": "除夕",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/1/29": {
		"name": "春节",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/1/30": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/1/31": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/2/1": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/2/2": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/2/3": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/2/4": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/2/8": {
		"name": "班",
    "isWorkingDay":true,
		"stress": false
	},
	"2025/2/12": {
		"name": "元宵节",
    "isWorkingDay":true,
		"stress": false
	},
	"2025/3/8": {
		"name": "妇女节",
    "isWorkingDay":true,
		"stress": false
	},
	"2025/4/4": {
		"name": "清明",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/4/5": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/4/6": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/4/27": {
		"name": "班",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/5/1": {
		"name": "劳动节",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/5/2": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/5/3": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/5/4": {
		"name": "青年节",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/5/5": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/5/31": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/6/1": {
		"name": "端午",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/6/2": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/8/29": {
		"name": "七夕",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/9/28": {
		"name": "班",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/10/1": {
		"name": "国庆",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/2": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/3": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/4": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/5": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/6": {
		"name": "中秋",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/7": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/8": {
		"name": "休",
    "isWorkingDay": false,
		"stress": true
	},
	"2025/10/11": {
		"name": "班",
    "isWorkingDay": true,
		"stress": false
	},
	"2025/12/25": {
		"name": "圣诞节",
    "isWorkingDay": true,
		"stress": false
	},
	"2026/1/1": {
		"name": "元旦",
    "isWorkingDay": false,
		"stress": true
	}
}

export const CHECKIN_TEXT = '入住';
export const CHECKOUT_TEXT = '离店';
// 酒店默认无图的图片
export const GDS_NONE_IMAGE = 'https://staticfile.badazhou.com/20200928/ae3b1edaa679b858f09fe5d71012e27c.jpg';

// 本地存储的 key 名
export const CHECK_DATE_KEY = 'checkDate';
export const CITY_KEY = 'city';

// 酒店默认图
export const hotelDefaultImage = "https://staticfile.badazhou.com/20200928/ae3b1edaa679b858f09fe5d71012e27c.jpg";
// 酒店房型默认图
export const hotelRoomDefaultImage = 'https://staticfile.badazhou.com/20210507/72c09eb5cf1a032a464ec1659227890a.jpeg';
// 加载图
export const loadingImage = "https://staticfile.badazhou.com/20201106/aac373fb1d1f81577c2ab7f1ad235427.gif";