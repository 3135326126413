import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { orderInfo } from '../../../../api/subject';
import { Button, message } from 'antd';
import './style.scss';

const PayResult = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSuccess = queryParams.get('res') === 'success';
  const orderNo = queryParams.get('order_no');

  function goToOrderList() {
    if (orderNo) {
      orderInfo({ order_no: orderNo }).then(res => {
        console.log('res: ', res);
        history.push({
          pathname: `/shanghaiCultureTourism/orderDetail`,
          state: { orderDetail: res.data }
        });
      })
    } else {
      message.error('获取订单详情失败');
    }
  }

  const iconUrl = isSuccess
    ? "https://staticfile.badazhou.com/20250304/59b09769ac1b649580cfb171d4a5d09e.png"
    : "https://staticfile.badazhou.com/20250304/603a3d96e789dd860ae352b990df9ed6.png";
  const messageInfo = isSuccess
    ? "支付成功"
    : "支付失败，请重试或联系客服";

  return (
    <div className="pay-result">
      <img className="result-icon" src={iconUrl} alt={isSuccess ? "支付成功" : "支付失败"} />
      <span className="result-message">{messageInfo}</span>
      <Button type="primary" htmlType="submit" onClick={goToOrderList} className="big-btn">
        查看订单
      </Button>
    </div>
  );
};

export default PayResult;
