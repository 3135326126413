import queryString from 'query-string';
import { get, post } from './request';
import jsbc from './request_jsbc';

/* 雀巢活动 开始 */
// 雀巢登陆
export function quechaoLogin(query) {
  let url = '/www/quechao/login';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}


// 是否参与雀巢活动
export function quechaoHasJoin(query) {
  let url = '/www/quechao/hasJoin';
  url = queryString.stringifyUrl({ url, query });
  return get(url);
}

// 参与活动
export function quechaoJoin(data) {
  return post('/www/quechao/join', data);
}

// 提交入住人信息
export function quechaoCheck(data) {
  return post('/www/quechao/checkinInfo', data);
}
/* 雀巢活动 结束 */

// 江苏银行
// 验证客户信息 
export function validCustomInfo(data) {
  return jsbc.post('/gapi/edge/jiangsu_bank/auth_customer', data);
}
// 生成订单
export function jsbcCreateOrder(data) {
  return jsbc.post('/gapi/edge/jiangsu_bank/payment/create', data);
}
// 支付异步通知
export function jsbcPayNotify(data) {
  return jsbc.post('/gapi/edge/jiangsu_bank/payment/notify', data);
}
// 产品列表
export function productList(query) {
  let url = '/gapi/edge/jiangsu_bank/product/list';
  url = queryString.stringifyUrl({ url, query });
  return jsbc.get(url);
}
// 订单列表 
export function orderList(query) {
  let url = '/gapi/edge/jiangsu_bank/order/list';
  url = queryString.stringifyUrl({ url, query });
  return jsbc.get(url);
}
// disney价格 
export function disneyPriceList(query) {
  let url = '/gapi/edge/jiangsu_bank/disney_price';
  url = queryString.stringifyUrl({ url, query });
  return jsbc.get(url);
}
// 订单详情
export function orderInfo(query) {
  let url = '/gapi/edge/jiangsu_bank/order_info';
  url = queryString.stringifyUrl({ url, query });
  return jsbc.get(url);
}
