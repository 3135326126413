import React, { useEffect, lazy, Suspense } from 'react';
import { validCustomInfo } from '../../../../api/subject.js';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import './style.scss';

import $ from '../../../../utils/jquery.js';
import '../../../../utils/jsbridge-1.0.4.js.js';
import '../../../../utils/thirdPayAll.js';


window.$ = $;
window.jQuery = $;

const TabBar = lazy(() => import('../tabBar/index.js'));

const ThirdPartyPage = () => {
  const history = useHistory();

  const tabBarConfig = [
    {
      title: '首页',
      path: '/shanghaiCultureTourism',
      icon: 'https://staticfile.badazhou.com/20250314/0dbef58fc98af4a8661f97c5e7976cd0.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/c9487140908382a0c0c64e040a1c6d77.png',
      selected: true
    },
    {
      title: '我的订单',
      path: '/shanghaiCultureTourism/order',
      icon: 'https://staticfile.badazhou.com/20250314/073d08fa59df0e644ba3205bddfed8e6.png',
      selectedIcon: 'https://staticfile.badazhou.com/20250314/32fedf07c7fec0f441d9f20321170727.png',
      selected: false
    }
  ];

  useEffect(() => {
    if (window.ThirdApp && typeof window.ThirdApp.initTitleNew === 'function') {
      window.ThirdApp.initTitleNew("约惠迪士尼", () => {
        if (window.ThirdApp && typeof window.ThirdApp.backToPre === 'function') {
          window.ThirdApp.backToPre();
        }
      });
    }

    // if (!localStorage.getItem('jsbc_token')) {
    getCustomerInfo();
    // }
  }, []);

  // 获取用户信息
  function getCustomerInfo() {
    if (window.ThirdApp && typeof window.ThirdApp.getCustomerInfo === 'function') {
      window.ThirdApp.getCustomerInfo(
        { channelId: "dzwldsn" },
        (successData) => {
          console.log('successData: ', successData);
          if (successData?.signature) {
            validCustomInfo({ encrypted_data: successData?.signature }).then(res => {
              const { phone, token, customer_no } = res.data;

              localStorage.setItem('jsbc_user', JSON.stringify({ phone, customer_no }));
              localStorage.setItem('jsbc_token', token);
            });
          }
        },
        (errorData) => {
          // console.error("用户信息过期：", JSON.stringify(errorData));
          message.error(`用户信息过期：${JSON.stringify(errorData)}`,);
        }
      );
    } else {
      console.error("ThirdApp 对象或 getCustomerInfo 方法不存在");
    }
  }

  function goToBuyTickets() {
    history.push('/shanghaiCultureTourism/ticket/hizea5kuroon');
  }

  return (
    <>

      <div className='jsbc-wrapper'>

        <div className="content">

          <div className="logo">
            <img className='img' src="https://staticfile.badazhou.com/20250314/8630d59c931c57983da292bf80326fd4.png" alt="logo" />
          </div>

          <div className="title">
            <img className='img' src="https://staticfile.badazhou.com/20250314/5ba9a958c43a2332368459c8b103547a.png" alt="logo" />
          </div>

          <div className="page-bg">
            <img src="https://staticfile.badazhou.com/20250317/9ac820da05686ab889c99f2cdd334e64.jpeg" alt="bg" />
          </div>

        </div>

        <div className="footer" onClick={goToBuyTickets}>

          <div className="desc">
            <img className='img' src="https://staticfile.badazhou.com/20250314/81f2c2e4b9f9b75343e8b24840d2919e.png" alt="logo" />
          </div>

          <div className='btn-box'>
            <img className='img' src="https://staticfile.badazhou.com/20250303/1c62c222ef2f27e894fd1aa740994cf2.png" alt="" />
          </div>

        </div>

        <Suspense fallback={<div>Loading...</div>}>
          <TabBar data={tabBarConfig} />
        </Suspense>

      </div>
    </>
  );
};

export default ThirdPartyPage;
