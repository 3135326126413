/* eslint-disable */
/**
 * 商户端接入js库 5.0 兼容 4.0
 * 有些三方系统  或者 个别方法  调用没有生效的话  可以把 相应的 JSON.stringify(cfg)  换成 cfg  即可
 * 
 * 4.0升级5.0 三方js说明
 * 
 * jsbridge-1.0.4.js  是新加的js  目的是升级5.0用到的
 * thirdPayAll.js  是4.0和5.0公用的方法，做了兼容处理。
 * 具体操作：先引入jsbridge-1.0.4.js再引入thirdPayAll.js即可（最好把原有的4.0复制到thirdPayAll.js 的 else 里）， 
 * 如果第三方是拿到thirdPayAll.js 改写的 需要在里面找到对应的方法重新改写即可。
 * 如果原先使用的方法在thirdPayAll.js中没有，和我们反馈 我们再处理，可能部分方法有遗漏。
 * 引入之后，安卓4.0/5.0 苹果4.0/5.0  共4个版本都需要重新测试。
 * 
 * 把原有的4.0 thirdPay.js中对应的方法复制到thirdPayAll.js 的同名方法的 if(ThirdApp.versionFiveFlag){}else{}  的else 中。
 * 
 */
var ThirdApp = {
  os: null,
  cbName: "",
  appVersion: 0,
  versionFiveFlag: true,
  /**
   * @private
   * @description 调用客户端方法
   * @param {string}
   *            funcName 方法名，js与客户端约定
   * @param {string}
   *            jsonData 传递参数
   */
  _callHandler: function (funcName, jsonData) {
    window.WebViewJavascriptBridge.callHandler(funcName, jsonData);
  },

  idSeed: 10000,
  /**
   * @description 获取唯一id
   * @returns {string} id
   * @example var _id = YT.id();
   */
  id: function () {
    return 'yt_gen_' + (++ThirdApp.idSeed);
  },
  /**
   * @description 转换为字符
   * @param {Object}
   *            v 转换对象
   * @returns {string} 返回字符串
   * @example var str = YT.toString({STATUS:"1"});
   */
  toString: function (v) {
    return Object.prototype.toString.apply(v);
  },
  /**
   * @description 是否是数组
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为数组
   * @example var arry = [];
   * var isArray = YT.isArray(arry);
   */
  isArray: function (v) {
    return ThirdApp.toString(v) === '[object Array]';
  },
  /**
   * @description 是否为空
   * @param {*}
   *            v 值
   * @param {boolean}
   *            allowBlank 是否允许空
   * @returns {*|boolean} 是否为空
   * @example var obj;
   * var isEmpty = YT.isEmpty(obj);
   */
  isEmpty: function (v, allowBlank) {
    return v === null || v === undefined
      || String(v).toUpperCase() === 'NULL'
      || ((ThirdApp.isArray(v) && !v.length))
      || (!allowBlank ? v === '' : false);
  },
  /**
   * @description 判断值是否已定义
   * @param {*}
   *            v 判断对象
   * @returns {*|boolean} 是否已定义
   * @example var fun;
   * var isDefined = YT.isDefined(fun);
   */
  isDefined: function (v) {
    return typeof v !== 'undefined';
  },

  /**
   * @description 是否是对象
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为对象
   * @example var obj = {};
   * var isObject = YT.isObject(obj);
   */
  isObject: function (v) {
    return !!v && ThirdApp.toString(v) === '[object Object]';
  },

  /**
   * @description 是否可迭代
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否可迭代
   * @example var arry = []; 
   * var isIterable = YT.isIterable(arry);
   */
  isIterable: function (v) {
    return (v && typeof v !== 'string') ? ThirdApp.isDefined(v.length) : false;
  },

  /**
   * @description 是否是数值型
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为数值
   * @example var b = 1;
   * var isNumber = YT.isNumber(b);
   */
  isNumber: function (v) {
    return typeof v === 'number' && ThirdApp.isFinite(v);
  },
  /**
   * @description 是否是字符型
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为字符串
   * @example var str = "test";
   * var isString = YT.isString(str);
   */
  isString: function (v) {
    return typeof v === 'string';
  },
  /**
   * @description 是否是布尔型
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为布尔型
   * @example var bool = true;
   * var isBoolean = YT.isBoolean(bool);
   */
  isBoolean: function (v) {
    return typeof v === 'boolean';
  },

  /**
   * @description 是否是原始类型
   * @param {*}
   *            v 值
   * @returns {*|boolean} 是否为原始类型
   * @example var bool = true; 
   * var isPrimitive = YT.isPrimitive(bool);
   */
  isPrimitive: function (v) {
    return ThirdApp.isString(v) || ThirdApp.isNumber(v) || ThirdApp.isBoolean(v);
  },

  /**
   * @description 遍历数组
   * @param {*}
   *            value 遍历对象
   * @param {function}
   *            fn 遍历处理函数
   * @param {*}
   *            scope 作用域
   * @example YT.each(['a','b'],function(v,i){
   * 	console.info(i+'-'+v);
   * });
   */
  each: function (value, fn, scope) {
    if (ThirdApp.isEmpty(value)) {
      return;
    }
    if (!ThirdApp.isDefined(scope)) {
      scope = value;
    }
    if (ThirdApp.isObject(value)) {
      var i = 0;
      for (var prop in value) {
        if (value.hasOwnProperty(prop)) {
          if (fn.call(scope || value, prop, value[prop], i++, value) === false) {
            return;
          }
        }
      }
    } else {
      if (!ThirdApp.isIterable(value) || ThirdApp.isPrimitive(value)) {
        value = [value];
      }
      for (var i = 0, len = value.length; i < len; i++) {
        if (fn.call(scope || value[i], value[i], i, value) === false) {
          return i;
        }
      }
    }
  },

  /**
   * @description String转换为JSON
   * @param {string}
   *            str json字符串
   * @returns {Object} json对象
   * @example YT.JsonEval('{"STATUS":"1"}');
   */
  JsonEval: function (str) {
    return eval("(" + str + ")");
  },

  /**
   * @description 获取方法名称
   * @param {function}
   *            func 方法对象
   * @param {boolean}
   *            decodeURL 是否转码，如果该函数的接收参数中包含URL，则该参数需要置为true
   * @returns {*} 临时方法名
   * @example cfg.success = YT.getFunctionName(cfg.success);
   * 临时函数调用：
   * window[cfg.success]&&window[cfg.success]();
   */
  getFunctionName: function (func, decodeURL) {
    if (func == null || func == "") {
      return "";
    }
    if (Object.prototype.toString.apply(func) !== '[object Function]') {
      return func;
    }
    var funcName = ThirdApp.id();
    // 创建可被调用的临时方法
    window[funcName] = function () {
      var args = [];
      ThirdApp.each(arguments, function (arg) {
        if (true == decodeURL) {
          arg = decodeURIComponent(arg);
        }
        if ('string' == typeof arg && '{' == arg.charAt(0)
          && '}' == arg.charAt(arg.length - 1)) {
          arg = ThirdApp.JsonEval(arg.replace(/<\/?[^>]*>/g, '').replace(
            /[\r\n]/g, '<br>'));
        }
        args.push(arg);
      }, this);
      func.apply(this, args);
      delete window[funcName];// 删除临时方法
    };
    return funcName;
  },

  /**
   * @description 获取版本号<br>
   * @param {function}
   *         callback 回调函数名称
   * @example YT.Client.getVersion(callback);
   */
  getVersion: function (callback) {
    var cfg = {
      callback: ThirdApp.getFunctionName(callback),
    };
    ThirdApp._callHandler("getVersion", JSON.stringify(cfg));
  },

  getVersion4: function (callback) {
    if (ThirdApp.os == 'android') {
      window.SysClientJs.getVersion(callback);
    } else if (ThirdApp.os == 'iphone') {
      _WK_DATAS["getVersion"] = {
        callBack: callback
      };
      setWebitEvent("_getVersion()", "A7");
    }
  },

  /**
   * 返回到一级菜单界面 5.0 by kangl
   * 
   */
  toHomePage: function () {
    if (ThirdApp.versionFiveFlag) {
      try {
        ThirdApp._callHandler("gotoIndex", {});
      } catch (e) {
        ThirdApp.alertinfo('返回首页异常', 'gotoIndex:' + e);
      }
    } else {
      if (ThirdApp.os == 'android') {
        window.SysClientJs.getHome('goHome()', '');
      } else if (ThirdApp.os == 'iphone') {
        _WK_DATAS["toHomePage"] = {};
        setWebitEvent("_toHomePage()", "B4");
      }
    }
  },
  /**
   * @description  返回首页<br>
   * @example YT.Client.gotoIndexForThird();
   */
  gotoIndexForThird: function () {
    try {
      var cfg = {
        callback: "clientMainPage",
      };
      ThirdApp._callHandler("gotoIndex", cfg);
    } catch (e) {
      YT.alertinfo('返回首页异常', 'gotoIndexForThird:' + e);
    }
  },
  /**
   * @description 返回上一页<br>
   *              接口名称：gotoBack <br>
   * @example YT.Client.gotoIndex();
   */
  gotoBack: function () {
    try {
      ThirdApp._callHandler("gotoBack", {});
    } catch (e) {
      ThirdApp.alertinfo('返回上一页异常', 'gotoBack:' + e);
    }
  },

  /**
   * 初始化标题头
   * @param title
   * @param callback
   */
  initTitleNew: function (title, callback) {
    try {
      var cfg = {
        title: title,
        leftButton: {
          exist: "true",
          name: "",
          func: ThirdApp.getFunctionName(callback)
        },
        rightButton: {
          exist: "false",
          name: "",
          func: ""
        },
        rightButton2: {
          exist: "false",
          name: "",
          func: ""
        }
      };
      ThirdApp._callHandler("initPageTitle", JSON.stringify(cfg));
    } catch (e) {
      ThirdApp.alertinfo('初始化页标题栏异常', 'initPageTitle:' + e);
    }

  },
  /**
   * 返回上一级
   * @param callback
   */
  backToPre: function (callback) {
    try {
      callback = ThirdApp.getFunctionName(callback);
      var cfg = {
        callback: callback,
      }
      ThirdApp._callHandler("backToPre", JSON.stringify(cfg));
    } catch (e) {
      ThirdApp.alertinfo('客户端或页面返回上一级异常', 'backToPre:' + e);
    }
  },

  /**
   * 第三方渠道获取客户信息
   * params 示例：送 {channelId:"hfcz"} channelId渠道号用手机银行根据渠道方定义
   */
  getCustomerInfo: function (params, success, failure) {
    var url = "lifeService/thirdObtainCustomerInfo.do";
    var cfg = {
      url: url,
      params: params,
      success: ThirdApp.getFunctionName(success),
      failure: ThirdApp.getFunctionName(failure),
    }
    ThirdApp._callHandler("post", JSON.stringify(cfg));
  },

  alertinfo: function (title, msg) {
    console.error(title + ": " + msg);
    // 或者根据需要实现弹窗提示，如 window.alert(title + ": " + msg);
  },
};

window.ThirdApp = ThirdApp;
// ThirdApp.init();

